<template lang="pug">
section.showroom.lazy(data-lazy-function="loaded")
  .showroom__wrapper.container.is-max-fullhd.is-flex
    .showroom__left-block
      .showroom__wrapper-left
        .showroom__subtitle.is-uppercase {{ $t('homePage.showroom.header.subTitle') }}
        h2.showroom__title.is-italic.is-capitalized {{ $t('homePage.showroom.header.messageTitle') }}
        .showroom__visit.is-flex
          icon(:width="12", :height="15", iconColor="#80704D", iconName="locations")
          span.is-uppercase {{ $t('showroom.header.city') }}
        .showroom__text {{ $t('homePage.showroom.header.messageText') }}
        .showroom__secutity(@click.prevent="$root.$emit('security-requirements')")
          i18n(path="showroom.header.review", :for="$t('showroom.header.security')")
            span(slot="link") {{ $t('showroom.header.security') }}
        .gallery-button__wrapper.is-hidden-tablet
        button.showroom__button.button.primary-color.light(@click="$root.$emit('showroom-gallery')")
          icon(height="22", width="26", icon-name="gallery", :icon-color="$site.defaultColor")
          span {{ $t('showroom.header.galleryButton') }}
    .showroom_right-block
      v-image.showroom__center-image(src="homePage/showroom/center@2x.jpg" :alt="$t('homePage.showroom.alt')")
      v-image.showroom__left-image(src="homePage/showroom/left@2x.jpg" :alt="$t('homePage.showroom.alt')")
      v-image.showroom__right-image(src="homePage/showroom/right@2x.jpg" :alt="$t('homePage.showroom.alt')")

</template>

<script>
export default {
  name: 'ShowroomComponent',

  mounted() {
    this.$root.$emit('security-requirements')
    this.$root.$emit('showroom-gallery')
  }
}
</script>

<style lang="sass" scoped>

.showroom
  &__wrapper
    width: 100%
    padding-top: 124px
    padding-bottom: 124px
    +touch
      gap: 20px
      padding: 54px 20px 81px
    +mobile
      flex-wrap: wrap
      gap: 60px
      padding-top: 80px
      padding-bottom: 60px

  &__left-block
    width: 50%
    +mobile
      width: 100%
  &__wrapper-left
    max-width: 505px
    width: 100%
    margin-top: 51px
    +touch
      margin-top: 0
    +mobile
      max-width: 100%
  &__subtitle
    font-size: 12px
    line-height: 24px
    text-transform: uppercase
    padding-bottom: 20px
  &__title
    font-family: $lora
    font-size: 36px
    line-height: 42px
    padding-bottom: 21px
    +touch
      font-size: 32px
      line-height: 42px
  &__text
    font-size: 16px
    line-height: 24px
    padding-bottom: 40px
    padding-right: 10px
    +touch
      padding-right: 0
  &__visit
    font-weight: bold
    font-size: 16px
    line-height: 24px
    padding-bottom: 15px
    align-items: center
    span
      font-weight: normal
      font-size: 12px
      line-height: 24px
      padding-left: 2px
  &__secutity
    margin-bottom: 40px
    cursor: pointer
    span
      font-weight: bold
      font-size: 16px
      line-height: 24px
      text-decoration: underline
    a
      font-size: 16px
      line-height: 24px
  &__button
    max-width: 355px
    width: 100%
    padding-top: 15px
    padding-bottom: 15px
    &:hover
      background-color: transparent!important
      color: $default-color!important
      border-color: $default-color!important
    span
      font-family: $lato
      font-weight: bold
      font-size: 14px
      padding-left: 20px
      line-height: 24px

  &_right-block
    position: relative
    width: 50%
    +touch
      margin-top: 48px
      max-width: 354px
      margin-left: auto
      margin-right: auto
    +mobile
      max-width: 335px
      width: 100%
      margin: 0 auto
    ::v-deep
      img
        max-width: 426px
        width: 100%
        aspect-ratio: 3 / 4
        +touch
          max-width: 314px
          aspect-ratio: 157 / 250
        +mobile
          max-width: 295px
          aspect-ratio: 59 / 92

  &__left-image,
  &__right-image
    max-width: 230px
    z-index: 1
    ::v-deep
      img
        max-width: 230px
        width: 100%
        aspect-ratio: 115 / 153
        margin: 0
        +until-fullhd
           max-width: 170px
        +touch
          max-width: 120px
          object-fit: cover
          aspect-ratio: 3 / 4
  &__left-image
    position: absolute
    top: 36px
    left: 0
    +touch
      top: 20px
    +mobile
      top: 40px
  &__right-image
    position: absolute
    top: 206px
    right: 0
    +until-fullhd
      top: 286px
    +touch
      top: 310px
    +mobile
      top: 260px
</style>
